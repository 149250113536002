import React from "react";
import Services from "../components/Services";

function ServicesPage() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-red-800 mb-8">Our Services</h1>
      <Services />
    </div>
  );
}

export default ServicesPage;
