import React from "react";
import Hero from "../components/Hero";
import Services from "../components/Services";
import About from "../components/About";
import Statistics from "../components/Statistics";
import Clients from "../components/Clients";
// import Blog from "../components/Blog";

function HomePage() {
  return (
    <div className="flex flex-col items-center">
      <Hero />
      <About />
      <Services />
      <Statistics />
      <Clients />
      {/* <Blog /> */}
    </div>
  );
}

export default HomePage;
