import React from 'react';
import About from '../components/About';
import Statistics from '../components/Statistics';

function AboutPage() {
  return (
    <div className="container mx-auto px-4 py-8">
      <About />
      <Statistics />
    </div>
  );
}

export default AboutPage;