import React, { useState, useEffect } from 'react';
import { motion, useAnimation, animate } from 'framer-motion';
import { Clock, Users, Briefcase, Users as Team } from 'lucide-react';

const stats = [
  { label: 'Years of Experience', value: 35, icon: Clock },
  { label: 'Satisfied Clients', value: 1000, icon: Users },
  { label: 'Projects Completed', value: 5000, icon: Briefcase },
  { label: 'Team Members', value: 100, icon: Team },
];

const CountUp = ({ end, duration = 5 }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const controls = animate(0, end, {
      duration: duration,
      ease: [0.33, 1, 0.68, 1], // cubic-bezier easing for smoother animation
      onUpdate: (value) => setCount(Math.round(value)),
    });

    return () => controls.stop();
  }, [end, duration]);

  return <span>{count}</span>;
};

const StatCard = ({ value, label, icon: Icon }) => {
  const controls = useAnimation();

  useEffect(() => {
    controls.start({ opacity: 1, y: 0 });
  }, [controls]);

  return (
    <motion.div
      className="bg-white bg-opacity-10 backdrop-blur-lg rounded-xl p-8 flex flex-col items-center text-center"
      initial={{ opacity: 0, y: 50 }}
      animate={controls}
      viewport={{ once: true, amount: 0.3 }}
      transition={{ duration: 0.8, delay: 0.2 }}
      whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
    >
      <motion.div
        className="text-red-300 mb-6"
        whileHover={{ rotate: 360 }}
        transition={{ duration: 0.8 }}
      >
        <Icon size={64} />
      </motion.div>
      <motion.p
        className="text-5xl font-bold mb-2"
        initial={{ scale: 0.5, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.5 }}
      >
        <CountUp end={value} />
        {label === 'Years of Experience' ? '+' : null}
      </motion.p>
      <motion.p 
        className="text-2xl"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.7 }}
      >
        {label}
      </motion.p>
    </motion.div>
  );
};

const MovingLines = () => (
  <div className="absolute inset-0 overflow-hidden">
    <motion.div
      className="absolute inset-0 opacity-10"
      initial={{ x: '100%' }}
      animate={{ x: '-100%' }}
      transition={{ repeat: Infinity, duration: 60, ease: 'linear' }}
    >
      {[...Array(10)].map((_, index) => (
        <div
          key={index}
          className="absolute top-0 bottom-0 w-px bg-white"
          style={{ left: `${index * 10}%` }}
        ></div>
      ))}
    </motion.div>
  </div>
);

function Statistics() {
  return (
    <div className="relative bg-gradient-to-b from-red-800 to-red-900 text-white py-32 lg:py-48 min-h-screen flex items-center overflow-hidden lg:mt-16">
      <MovingLines />
      <div className="container mx-auto px-6 relative z-10">
        <motion.h2
          className="text-6xl lg:text-7xl font-bold text-center mb-20"
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          Our Impact in Numbers
        </motion.h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-16">
          {stats.map((stat, index) => (
            <StatCard key={index} {...stat} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Statistics;