import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X, ChevronUp, FileText } from 'lucide-react';
import { motion } from 'framer-motion';
import logo from './Images/logo.png';
import brochurePDF from './brochure.pdf';  

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    let menuTimer;
    if (isMenuOpen) {
      menuTimer = setTimeout(() => {
        setIsMenuOpen(false);
      }, 3000);
    }

    return () => {
      clearTimeout(menuTimer);
    };
  }, [isMenuOpen]);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const menuVariants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  };

  const menuItemVariants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  };

  return (
    <header
      className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
        isScrolled ? 'bg-white bg-opacity-90 shadow-md' : 'bg-transparent'
      }`}
    >
      <div className="container mx-auto px-4 py-4 flex items-center justify-between">
        <Link to="/" className="flex items-center">
          <img src={logo} alt="Trans International Packers & Movers" className="h-12 w-auto" />
        </Link>

        <nav className="hidden md:flex items-center space-x-6">
          <Link
            to="/"
            className="text-gray-800 hover:text-red-800 transition duration-300"
          >
            Home
          </Link>
          <Link
            to="/services"
            className="text-gray-800 hover:text-red-800 transition duration-300"
          >
            Services
          </Link>
          <a
            href={brochurePDF}
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-800 hover:text-red-800 transition duration-300 flex items-center"
          >
            About 
          </a>
          <Link
            to="/contact"
            className="bg-red-800 text-white px-4 py-2 rounded-full hover:bg-red-700 transition duration-300"
          >
            Get a Quote
          </Link>
        </nav>

        <button
          className="md:hidden text-red-800 focus:outline-none"
          onClick={toggleMenu}
          aria-label="Toggle menu"
        >
          {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </div>

      {/* Mobile Navigation Menu */}
      <motion.div
        initial={false}
        animate={isMenuOpen ? 'open' : 'closed'}
        className={`fixed inset-y-0 right-0 bg-red-800 z-40 w-[80vw] max-w-sm transform transition-transform duration-300 ease-in-out ${
          isMenuOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="relative h-full flex flex-col py-8" variants={menuVariants}>
          <button
            className="absolute top-4 right-4 text-white focus:outline-none"
            onClick={toggleMenu}
            aria-label="Close menu"
          >
            <X size={24} />
          </button>
          <motion.div
            className="flex-grow flex flex-col items-center justify-center"
            variants={menuVariants}
          >
            <motion.div
              variants={menuItemVariants}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              <Link
                to="/"
                className="text-white text-2xl my-4 hover:text-yellow-300 transition duration-300"
                onClick={toggleMenu}
              >
                Home
              </Link>
            </motion.div>
            <motion.div
              variants={menuItemVariants}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              <Link
                to="/services"
                className="text-white text-2xl my-4 hover:text-yellow-300 transition duration-300"
                onClick={toggleMenu}
              >
                Services
              </Link>
            </motion.div>
            <motion.div
              variants={menuItemVariants}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              <a
                href={brochurePDF}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white text-2xl my-4 hover:text-yellow-300 transition duration-300 flex items-center"
                onClick={toggleMenu}
              >
                About
              </a>
            </motion.div>
            <motion.div className="mt-8" variants={menuItemVariants}>
              <Link
                to="/contact"
                className="mt-6 bg-white text-red-800 px-6 py-3 rounded-full hover:bg-yellow-300 transition duration-300"
                onClick={toggleMenu}
              >
                Get a Quote
              </Link>
            </motion.div>
          </motion.div>
        </div>
      </motion.div>

      {/* Back to Top Button */}
      <button
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        className={`fixed bottom-4 right-4 bg-red-800 text-white p-2 rounded-full shadow-lg transition-opacity duration-300 ${
          isScrolled ? 'opacity-100' : 'opacity-0'
        }`}
        aria-label="Back to top"
      >
        <ChevronUp size={24} />
      </button>
    </header>
  );
}

export default Header;