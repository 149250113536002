import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { MapPin, Phone, Mail, Send } from 'lucide-react';

const MovingLines = () => (
  <div className="absolute inset-0 overflow-hidden">
    <motion.div
      className="absolute inset-0 opacity-10"
      initial={{ x: '100%' }}
      animate={{ x: '-100%' }}
      transition={{ repeat: Infinity, duration: 60, ease: 'linear' }}
    >
      {[...Array(10)].map((_, index) => (
        <div
          key={index}
          className="absolute top-0 bottom-0 w-px bg-white"
          style={{ left: `${index * 10}%` }}
        ></div>
      ))}
    </motion.div>
  </div>
);

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://formcarry.com/s/Cx4a72ogRov', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSubmitted(true);
        setFormData({ name: '', email: '', phone: '', message: '' });
      } else {
        throw new Error('Failed to submit form');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <form className="space-y-6" onSubmit={handleSubmit}>
      {submitted && <p className="text-green-500">Thank you! Your message has been sent.</p>}
      {error && <p className="text-red-500">{error}</p>}
      <div>
        <label htmlFor="name" className="block text-sm font-medium mb-2">Name</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          className="w-full px-3 py-2 bg-white bg-opacity-20 rounded-md text-white placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-red-300"
          placeholder="Your Name"
        />
      </div>
      <div>
        <label htmlFor="email" className="block text-sm font-medium mb-2">Email</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          className="w-full px-3 py-2 bg-white bg-opacity-20 rounded-md text-white placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-red-300"
          placeholder="your@email.com"
        />
      </div>
      <div>
        <label htmlFor="phone" className="block text-sm font-medium mb-2">Phone Number</label>
        <input
          type="tel"
          id="phone"
          name="phone"
          value={formData.phone}
          onChange={handleInputChange}
          className="w-full px-3 py-2 bg-white bg-opacity-20 rounded-md text-white placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-red-300"
          placeholder="Your Phone Number"
        />
      </div>
      <div>
        <label htmlFor="message" className="block text-sm font-medium mb-2">Message</label>
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleInputChange}
          rows="4"
          className="w-full px-3 py-2 bg-white bg-opacity-20 rounded-md text-white placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-red-300"
          placeholder="Your message here..."
        ></textarea>
      </div>
      <motion.button
        type="submit"
        className="w-full bg-red-600 text-white py-2 px-4 rounded-md hover:bg-red-700 transition duration-300 flex items-center justify-center"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <Send className="mr-2" size={18} />
        Send Message
      </motion.button>
    </form>
  );
};

function ContactPage() {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  return (
    <div className="relative bg-gradient-to-b from-red-800 to-red-900 text-white min-h-screen py-24">
      <MovingLines />
      <div className="container mx-auto px-4 relative z-10">
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <motion.h1 
            className="text-4xl font-bold mb-12 text-center"
            variants={itemVariants}
          >
            Contact Us
          </motion.h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <motion.div 
              variants={itemVariants}
              className="bg-white bg-opacity-10 backdrop-blur-lg rounded-xl p-8"
            >
              <h2 className="text-2xl font-semibold mb-6">Get in Touch</h2>
              <p className="mb-8">We'd love to hear from you. Please fill out the form or use our contact information.</p>
              <div className="space-y-6">
                <div className="flex items-start">
                  <MapPin size={24} className="mr-4 mt-1 flex-shrink-0 text-red-300" />
                  <div>
                    <h3 className="text-xl font-semibold mb-2">Address</h3>
                    <p>Noah Street, Shivajinagar,</p>
                    <p>Bangalore- 560051</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <Phone size={24} className="mr-4 flex-shrink-0 text-red-300" />
                  <div>
                    <h3 className="text-xl font-semibold mb-2">Phone</h3>
                    <p>+91 9844028614</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <Mail size={24} className="mr-4 flex-shrink-0 text-red-300" />
                  <div>
                    <h3 className="text-xl font-semibold mb-2">Email</h3>
                    <p>jawad.ahmed@transinternationalpackers.com</p>
                  </div>
                </div>
              </div>
            </motion.div>
            <motion.div 
              variants={itemVariants}
              className="bg-white bg-opacity-10 backdrop-blur-lg rounded-xl p-8"
            >
              <ContactForm />
            </motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default ContactPage;