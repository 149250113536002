import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FileText } from 'lucide-react';
import image1 from './Images/packing1.png';
import image2 from './Images/packing2.png';
import image3 from './Images/packing3.png';
import image4 from './Images/packing4.png';
import brochurePDF from './brochure.pdf';

const images = [
  image1,
  image2,
  image3,
  image4
];

const MovingLines = () => (
  <div className="absolute inset-0 overflow-hidden">
    <motion.div
      className="absolute inset-0 opacity-10"
      initial={{ x: '100%' }}
      animate={{ x: '-100%' }}
      transition={{ repeat: Infinity, duration: 60, ease: 'linear' }}
    >
      {[...Array(10)].map((_, index) => (
        <div
          key={index}
          className="absolute top-0 bottom-0 w-px bg-white"
          style={{ left: `${index * 10}%` }}
        ></div>
      ))}
    </motion.div>
  </div>
);

function About() {
  const [currentImage, setCurrentImage] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 2500);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative bg-gradient-to-b from-red-800 to-red-900 text-white py-24 lg:py-32 lg:mt-16 lg:mb-16 min-h-screen flex items-center overflow-hidden">
      <MovingLines />
      <div className="container mx-auto px-4 relative z-10">
        <motion.h2
          className="text-4xl lg:text-5xl font-bold text-center mb-12"
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          About Us
        </motion.h2>
        <div className="flex flex-col lg:flex-row items-center lg:items-start space-y-8 lg:space-y-0 lg:space-x-12">
          <motion.div
            className="w-full lg:w-1/2 relative"
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            viewport={{ once: true }}
          >
            <motion.div
              className="relative w-[90%] sm:w-full h-[300px] sm:h-[400px] lg:h-[500px] rounded-lg overflow-hidden shadow-2xl mx-auto" animate={{ y: [0, -10, 0] }} transition={{
                duration: 5,
                repeat: Infinity,
                repeatType: "reverse",
                ease: "easeInOut"
              }}
            >
              {/* White glowing border animation */}
              <motion.div
                className="absolute inset-0 rounded-lg"
                initial={{ boxShadow: '0 0 0 0 rgba(255,255,255,0)' }}
                animate={{ boxShadow: ['0 0 0 0 rgba(255,255,255,0)', '0 0 0 4px rgba(255,255,255,0.5)', '0 0 0 0 rgba(255,255,255,0)'] }}
                transition={{ duration: 2, repeat: Infinity, ease: 'easeInOut' }}
              />
              {images.map((img, index) => (
                <motion.img
                  key={img}
                  src={img}
                  alt={`Trans International Packing ${index + 1}`}
                  className="absolute inset-0 w-full h-full object-cover"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: currentImage === index ? 1 : 0 }}
                  transition={{ duration: 0.5 }}
                />
              ))}
            </motion.div>
          </motion.div>
          <motion.div
            className="w-full lg:w-1/2"
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            viewport={{ once: true }}
          >
            <h3 className="text-2xl lg:text-3xl font-semibold mb-6">Welcome to Trans International Packers & Movers</h3>
            <p className="text-lg mb-6">
              We have been providing high quality, cost-effective and reliable packaging solutions for various industries since 1984. Our mission is to deliver quality and durability you can trust.
            </p>
            <p className="text-lg mb-6">
              We have a team of experienced and skilled packers who ensure the quality and accuracy of our packing services. We also have a network of reliable and reputable logistics partners who handle the transportation and delivery of your products.
            </p>
            <p className="text-lg mb-8">
              Packing is not just a way to protect your products, but also a crucial part of the logistics process that can affect your efficiency, profitability, and customer satisfaction.
            </p>
            <motion.a
              href={brochurePDF}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center px-6 py-3 bg-white text-red-800 rounded-full hover:bg-yellow-300 transition duration-300"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <FileText className="mr-2" size={20} />
              More About Us
            </motion.a>
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default About;