import React from 'react';
import { motion } from 'framer-motion';
import { Package, Truck, Box } from 'lucide-react';

const servicesList = [
  {
    title: 'Customized Packing',
    description: 'Tailor-made packages designed to fit your products\' specifications, dimensions, and weight.',
    icon: Package,
  },
  {
    title: 'Standardized Packing',
    description: 'Ready-made packages complying with international standards for various modes of transportation.',
    icon: Truck,
  },
  {
    title: 'Packing Equipment',
    description: 'Supply of various packing equipment and tools to help you pack efficiently and effectively.',
    icon: Box,
  },
];

const ServiceCard = ({ title, description, icon: Icon }) => (
  <motion.div
    className="bg-white bg-opacity-10 backdrop-blur-lg rounded-xl p-8 flex flex-col items-center text-center h-full"
    initial={{ opacity: 0, y: 50 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true, amount: 0.3 }}
    transition={{ duration: 0.5 }}
    whileHover={{ scale: 1.03 }}
  >
    <motion.div
      className="text-red-300 mb-6"
      whileHover={{ rotate: 360 }}
      transition={{ duration: 0.5 }}
    >
      <Icon size={64} />
    </motion.div>
    <h3 className="text-3xl font-bold mb-4">{title}</h3>
    <p className="text-gray-200 text-lg">{description}</p>
  </motion.div>
);

const MovingLines = () => (
  <div className="absolute inset-0 overflow-hidden">
    <motion.div
      className="absolute inset-0 opacity-10"
      initial={{ x: '100%' }}
      animate={{ x: '-100%' }}
      transition={{ repeat: Infinity, duration: 60, ease: 'linear' }}
    >
      {[...Array(10)].map((_, index) => (
        <div
          key={index}
          className="absolute top-0 bottom-0 w-px bg-white"
          style={{ left: `${index * 10}%` }}
        ></div>
      ))}
    </motion.div>
  </div>
);

function Services() {
  return (
    <div className="relative bg-gradient-to-b from-red-800 to-red-900 text-white py-32 lg:py-48 min-h-screen flex items-center overflow-hidden">
      <MovingLines />
      <div className="container mx-auto px-6 relative z-10">
        <motion.h2
          className="text-6xl lg:text-7xl font-bold text-center mb-20"
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          Our Professional Services
        </motion.h2>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-16">
          {servicesList.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Services;