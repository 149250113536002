import React from 'react';
import { motion } from 'framer-motion';

// Import all client logos
import wipro from './Client-Images/Wipro.png';
import tata from './Client-Images/Tata.png';
import sapa from './Client-Images/SAPA.png';
import alcatelLucent from './Client-Images/Alcatel_Lucent.png';
import nokia from './Client-Images/NOKIA.png';
import gokuldas from './Client-Images/Gokuldas.png';

const clients = [
  { name: 'Wipro', logo: wipro },
  { name: 'Tata', logo: tata },
  { name: 'SAPA', logo: sapa },
  { name: 'Alcatel Lucent', logo: alcatelLucent },
  { name: 'NOKIA', logo: nokia },
  { name: 'Gokuldas', logo: gokuldas },
];

const MovingLines = () => (
  <div className="absolute inset-0 overflow-hidden">
    {[...Array(3)].map((_, groupIndex) => (
      <motion.div
        key={groupIndex}
        className="absolute inset-0 opacity-10"
        initial={{ x: '100%' }}
        animate={{ x: '-100%' }}
        transition={{
          repeat: Infinity,
          duration: 60,
          ease: 'linear',
          delay: groupIndex * 20
        }}
      >
        {[...Array(10)].map((_, index) => (
          <div
            key={index}
            className="absolute top-0 bottom-0 w-px bg-white"
            style={{ left: `${index * 10}%` }}
          ></div>
        ))}
      </motion.div>
    ))}
  </div>
);

const ClientSlide = ({ client }) => (
  <motion.div
    className="bg-white bg-opacity-10 backdrop-blur-lg p-8 rounded-xl shadow-md flex items-center justify-center min-w-[270px] h-[270px] mx-4"
    whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
  >
    <img
      src={client.logo}
      alt={client.name}
      className="w-full h-full object-contain"
    />
  </motion.div>
);

function Clients() {
  return (
    <div className="relative bg-gradient-to-b from-red-800 to-red-900 text-white min-h-screen flex flex-col justify-center items-center overflow-hidden lg:mt-12 lg:mb-12">
      <MovingLines />
      <div className="container mx-auto px-4 relative z-10">
        <motion.h2
          className="text-5xl lg:text-6xl font-bold text-center mb-16"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Our Clients
        </motion.h2>
        <motion.div
          className="flex space-x-8"
          animate={{
            x: [0, -500 * clients.length],
          }}
          transition={{
            x: {
              repeat: Infinity,
              repeatType: "loop",
              duration: 30,
              ease: "linear",
            },
          }}
        >
          {[...clients, ...clients].map((client, index) => (
            <ClientSlide key={index} client={client} />
          ))}
        </motion.div>
      </div>
    </div>
  );
}

export default Clients;