import React from 'react';
import { motion } from 'framer-motion';
import { Phone, Mail, MapPin, Facebook, Twitter, Instagram, Linkedin } from 'lucide-react';

const MovingLines = () => (
  <div className="absolute inset-0 overflow-hidden">
    <motion.div
      className="absolute inset-0 opacity-10"
      initial={{ x: '100%' }}
      animate={{ x: '-100%' }}
      transition={{ repeat: Infinity, duration: 60, ease: 'linear' }}
    >
      {[...Array(10)].map((_, index) => (
        <div
          key={index}
          className="absolute top-0 bottom-0 w-px bg-white"
          style={{ left: `${index * 10}%` }}
        ></div>
      ))}
    </motion.div>
  </div>
);

function Footer() {
  const currentYear = new Date().getFullYear();

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  return (
    <footer className="relative bg-gradient-to-b from-red-800 to-red-900 text-white overflow-hidden">
      <MovingLines />
      <div className="container mx-auto px-4 py-12 relative z-10">
        <motion.div
          className="grid grid-cols-1 md:grid-cols-3 gap-8"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <motion.div variants={itemVariants}>
            <h3 className="text-xl font-semibold mb-4">Contact Us</h3>
            <ul className="space-y-2">
              <li className="flex items-center">
                <MapPin size={18} className="mr-2" />
                <span>Noah Street, Shivajinagar, Bangalore- 560051</span>
              </li>
              <li className="flex items-center">
                <Phone size={18} className="mr-2" />
                <span>+91 9844028614</span>
              </li>
              <li className="flex items-center">
                <Mail size={18} className="mr-2" />
                <span>jawad.ahmed@transinternationalpackers.com</span>
              </li>
            </ul>
          </motion.div>
          <motion.div variants={itemVariants}>
            <h3 className="text-xl font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <a href="/services" className="hover:text-red-300 transition-colors duration-300">Services</a>
              </li>
              <li>
                <a href="/about" className="hover:text-red-300 transition-colors duration-300">About Us</a>
              </li>
              <li>
                <a href="/contact" className="hover:text-red-300 transition-colors duration-300">Contact</a>
              </li>
            </ul>
          </motion.div>
          <motion.div variants={itemVariants}>
            <h3 className="text-xl font-semibold mb-4">Follow Us</h3>
            <div className="flex space-x-4">
              <a href="#" className="hover:text-red-300 transition-colors duration-300">
                <Facebook size={24} />
              </a>
              <a href="#" className="hover:text-red-300 transition-colors duration-300">
                <Twitter size={24} />
              </a>
              <a href="#" className="hover:text-red-300 transition-colors duration-300">
                <Instagram size={24} />
              </a>
              <a href="#" className="hover:text-red-300 transition-colors duration-300">
                <Linkedin size={24} />
              </a>
            </div>
          </motion.div>
        </motion.div>
        <motion.div
          className="mt-12 text-center"
          variants={itemVariants}
          initial="hidden"
          animate="visible"
        >
          <p>&copy; {currentYear} Trans International Packers & Movers. All rights reserved.</p>
        </motion.div>
      </div>
    </footer>
  );
}

export default Footer;